var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"size":"lg","centered":"","scrollable":"","title":_vm.modalTitle},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return cancel()}}},[_vm._v(" Închide ")]),(_vm.modalState === _vm.modalStates.view)?_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteProduct(ok)}}},[_vm._v(" Șterge ")]):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.onOk(ok)}}},[_vm._v(" "+_vm._s(_vm.okButtonName)+" ")])]}}])},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"Cod:"}},[_c('b-form-input',{class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"type":"text","trim":"","placeholder":"ex. ABC","disabled":_vm.modalState !== _vm.modalStates.add && _vm.modalState !== _vm.modalStates.addExternal},model:{value:(_vm.productCode),callback:function ($$v) {_vm.productCode=$$v},expression:"productCode"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1),(_vm.autoGenerateCode)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('catalogManagement.productCatalog.autoGenerateCodeWarning'))+" ")]):_vm._e()],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"Denumire:"}},[_c('b-form-input',{class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"type":"text","trim":"","placeholder":"ex. Vopsea","disabled":_vm.modalState === _vm.modalStates.view},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"Tip:"}},[_c('multiselect',{staticClass:"multiselect-type",class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"track-by":"type","label":"label","select-label":"👈","deselect-label":"❌","selected-label":"✔","options":_vm.productTypeOptions,"allow-empty":false,"searchable":true,"close-on-select":true,"show-labels":true,"placeholder":"Toate","disabled":_vm.modalState === _vm.modalStates.view},on:{"select":_vm.onProductTypeChange},model:{value:(_vm.productType),callback:function ($$v) {_vm.productType=$$v},expression:"productType"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"Clasificare:"}},[_c('multiselect',{staticClass:"multiselect-type",class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"track-by":"key","label":"label","select-label":"👈","deselect-label":"❌","selected-label":"✔","options":_vm.productClassificationOptions,"allow-empty":false,"searchable":true,"close-on-select":true,"show-labels":true,"placeholder":"Toate","disabled":_vm.modalState === _vm.modalStates.view},on:{"input":function($event){return _vm.validateWrapper(validate, _vm.classification)},"close":function($event){return _vm.onProductClassificationClose(validate, _vm.classification)}},model:{value:(_vm.classification),callback:function ($$v) {_vm.classification=$$v},expression:"classification"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"UM:"}},[_c('multiselect',{staticClass:"multiselect-um",class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"track-by":"key","label":"label","select-label":"👈","deselect-label":"❌","selected-label":"✔","options":_vm.measurementUnitOptions,"searchable":true,"close-on-select":true,"show-labels":true,"placeholder":"Toate","disabled":_vm.modalState === _vm.modalStates.view},on:{"input":function($event){return _vm.validateWrapper(validate, _vm.productMU)},"close":function($event){return _vm.validateWrapper(validate, _vm.productMU)}},model:{value:(_vm.productMU),callback:function ($$v) {_vm.productMU=$$v},expression:"productMU"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"Moneda:"}},[_c('multiselect',{staticClass:"multiselect-currency",class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"label":"label","track-by":"key","select-label":"👈","deselect-label":"","selected-label":"✔","placeholder":"...","allow-empty":false,"options":_vm.currencyCodeOptions,"searchable":true,"close-on-select":true,"show-labels":true,"disabled":_vm.modalState === _vm.modalStates.view},on:{"input":function($event){return _vm.validateWrapper(validate, _vm.currencyCode)},"close":function($event){return _vm.validateWrapper(validate, _vm.currencyCode)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',[_vm._v(_vm._s(props.option.label)+" - "+_vm._s(props.option.description))])]}}],null,true),model:{value:(_vm.currencyCode),callback:function ($$v) {_vm.currencyCode=$$v},expression:"currencyCode"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"Pret referinta:"}},[_c('b-form-input',{class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"type":"text","trim":"","placeholder":"ex. 10","disabled":_vm.modalState === _vm.modalStates.view},model:{value:(_vm.productPrice),callback:function ($$v) {_vm.productPrice=$$v},expression:"productPrice"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{staticClass:"col-6",attrs:{"label-size":"sm","description":"","label":"Procent TVA:"}},[_c('multiselect',{class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"label":"label","track-by":"key","select-label":"👈","deselect-label":"","selected-label":"✔","allow-empty":false,"options":_vm.vatPercentageOptions,"searchable":true,"close-on-select":true,"show-labels":true,"disabled":_vm.modalState === _vm.modalStates.view},model:{value:(_vm.vatPercentage),callback:function ($$v) {_vm.vatPercentage=$$v},expression:"vatPercentage"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('ValidationProvider',{staticClass:"col-6",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('b-form-group',{attrs:{"label-size":"sm","description":"","label":"Cont evidență:"}},[_c('multiselect',{staticClass:"multiselect-type",class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"track-by":"key","label":"label","select-label":"👈","deselect-label":"❌","selected-label":"✔","options":_vm.xcontaTrackingAccountOptions,"allow-empty":false,"searchable":true,"close-on-select":true,"show-labels":true,"placeholder":"Toate","disabled":_vm.modalState === _vm.modalStates.view},on:{"input":function($event){return _vm.validateWrapper(validate, _vm.xcontaTrackingAccount)},"close":function($event){return _vm.validateWrapper(validate, _vm.xcontaTrackingAccount)}},model:{value:(_vm.xcontaTrackingAccount),callback:function ($$v) {_vm.xcontaTrackingAccount=$$v},expression:"xcontaTrackingAccount"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])}),_c('b-form-group',{staticClass:"col-6",attrs:{"label-size":"sm","description":"","label":"Stare:"}},[_c('b-form-select',{staticStyle:{"background-color":"#e8f0f0"},attrs:{"id":"select-status-produs","options":_vm.statusProdusOptions,"disabled":_vm.modalState === _vm.modalStates.view,"value":true},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }